import { HttpClient } from '@wix/http-client';
import { BadgeType } from '@wix/members-badge-lib';

interface BadgeListResponse {
  badges: BadgeType[];
}

export class NewBadgesService {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  readonly getBadgeList = async () => {
    const url = `${this.baseUrl}/badges`;
    const { data } = await this.httpClient.get<BadgeListResponse>(url);

    return data.badges;
  };
}
