import { MemberToMemberBlocksApi } from '@wix/ambassador-member-to-member-blocks-api/http';
import { memberBlockMemberServiceBaseUrl } from '../constants/urls';
import { Handler } from '../types';

class BlockMemberService {
  constructor(private readonly getInstance: Handler<string>) {}

  memberBlockMemberApi = MemberToMemberBlocksApi(
    memberBlockMemberServiceBaseUrl,
  ).MemberToMemberBlocks();

  async blockMember(memberId: string) {
    return this.memberBlockMemberApi({
      Authorization: this.getInstance(),
    }).blockMember({ memberId });
  }
}

export default BlockMemberService;
